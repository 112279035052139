import TitleWhite from '../repeatable/titleWhite'
import CardChoose from './cardChoose'
import why1Image from '../../assets/img/why-icon-1.svg'
import why2Image from '../../assets/img/why-icon-2-svg.svg'
import why3Image from '../../assets/img/why-icon-3.svg'
import ButtonLink from '../repeatable/buttonLink'

export default function Choose () {
  const list = [
    {
      image: why1Image,
      title: 'Get started in minutes',
      desc: 'Getting started is free and simple. We’ll get you set up and answer any questions you have throughout the process.'
    },
    {
      image: why2Image,
      title: 'Win more jobs',
      desc: 'Stop wasting time finding different design and engineering partners. On average, we save 2 months of lost time per project.'
    },
    {
      image: why3Image,
      title: 'Fastest path to permit',
      desc: 'Constructo delivers permit-ready project plan sets and construction documents for the smoothest permitting process.'
    }
  ]

  return (
    <>
      <div className='py-24 bg-repeat bg-cover bg-main-blue bg-hero-bg'>
        <div className='max-w-screen-xl mx-auto max-xl:px-6'>
          <TitleWhite
            start='Why home builders choose'
            after='Constructo'
            desc='Builders across the country choose Constructo for overall ease-of-use, increase in project profitability, and permitting speed.'
          />
          <div className='flex w-full mt-24 max-lg:flex-col lg:space-x-8'>
            {list &&
              list.map((item, index) => {
                return (
                  <CardChoose
                    image={item.image}
                    title={item.title}
                    desc={item.desc}
                  />
                )
              })}
          </div>
          <div className='flex justify-center mt-20'>
            <ButtonLink text='Talk to an Expert' />
          </div>
        </div>
      </div>
    </>
  )
}
