import leftImg from '../../assets/img/cta-shape-left.svg'
import rightImg from '../../assets/img/cta-shape-bottom.svg'
import { Typography } from '@material-tailwind/react'
import ButtonLink from '../repeatable/buttonLink'
export default function BeforeFooter () {
  return (
    <>
      <div className='bg-[#ecf2f9]'>
        <div className='relative max-w-screen-xl max-xl:px-6 sm:py-12 mx-auto z-20 -mt-[200px] bg-main-blue'>
          <img src={leftImg} className='absolute top-0 left-0 h-full' alt='' />
          <img src={rightImg} className='absolute bottom-0 right-0' alt='' />
          <div className='relative z-20 max-w-screen-md py-12 mx-auto'>
            <Typography className='text-2xl sm:text-5xl font-bold  sm:leading-[67px] text-center text-white'>
              Preconstruction is complex, we’ll simplify it for you.
            </Typography>
            <Typography className='mt-6 text-2xl text-center text-white'>
              You’re one step away from your smoothest project yet.
            </Typography>
            <div className='flex justify-center mt-12'>
              <ButtonLink text='Talk to an Expert' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
