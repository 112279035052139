import TitleBlack from '../repeatable/titleBlack'
import image1 from '../../assets/img/sol1.avif'
import image2 from '../../assets/img/process-illustration-2.svg'
import image3 from '../../assets/img/3.avif'
import image4 from '../../assets/img/4.avif'
import CardSolution from './cardSolutions'
export default function Solution () {
  const list = [
    {
      image: image1,
      title: 'A dedicated project team.',
      description:
        'Constructo is your dedicated team of preconstruction experts. Get a direct line to your team for fast, effective help and resolutions.',
      text: [
        { desc: 'State and local experts' },
        { desc: 'Regular project check-ins' },
        { desc: 'Direct team access' }
      ]
    },
    {
      image: image2,
      title: 'Powerful workflow software.',
      description:
        'Visually manage all your design and permitting projects in one place. Assign tasks, chat with your team, and access project documents.',
      text: [
        { desc: 'Drag-and-drop project board' },
        { desc: 'Simple task management' },
        { desc: 'Project document storage' }
      ]
    },
    {
      image: image3,
      title: 'Click-to-order services.',
      description:
        'Get unlimited access to a variety of on-demand preconstruction services. Available to order directly in the Constructo platform.',
      text: [
        { desc: '3D site scanning and measurements' },
        { desc: 'As-builts and architectural plans' },
        { desc: 'Structural drawings and calculations' }
      ]
    },
    {
      image: image4,
      title: 'Permitting, minus the stress.',
      description:
        'We take the legwork out of pulling building permits. With Constructo, you get permit-ready project plans with all the required paperwork.',
      text: [
        { desc: 'Full project plan sets' },
        { desc: 'Construction docs' },
        { desc: 'Application coordination' }
      ]
    }
  ]

  return (
    <>
      <div className='bg-[#f8f9fc] max-xl:px-6'>
        <div className='max-w-screen-xl py-12 mx-auto'>
          <TitleBlack
            start='The'
            after='ultimate'
            end='design to permit solution.'
            desc='With Constructo, builders get access to a dedicated team of experts, click-to-order services, and built-in project tools for a seamless design and permitting process.'
          />
        </div>
        <div className='max-w-screen-xl mx-auto'>
          {list &&
            list.map((item, index) => {
              return (
                <CardSolution
                  key={index}
                  class={
                    index % 2 != 0
                      ? 'flex-row max-lg:flex-col'
                      : 'flex-row-reverse max-lg:flex-col'
                  }
                  image={item.image}
                  title={item.title}
                  description={item.description}
                  data={item.text}
                />
              )
            })}
        </div>
      </div>
    </>
  )
}
