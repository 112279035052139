import Home from './Pages/home'
import Footer from './Pages/layouts/footer'
import Header from './Pages/layouts/header'

function App () {
  return (
    <>
      <Header />
      <Home />
      <Footer />
    </>
  )
}

export default App
