import React from 'react'
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  IconButton
} from '@material-tailwind/react'
import { Link } from 'react-scroll'

import Logo from '../../assets/img/9963a762-abb5-4003-ae43-c909fe1a82f9_logo-2x.avif'
import ButtonLink from '../repeatable/buttonLink'
export default function Header () {
  const [openNav, setOpenNav] = React.useState(false)

  React.useEffect(() => {
    window.addEventListener(
      'resize',
      () => window.innerWidth >= 960 && setOpenNav(false)
    )
  }, [])

  const navList = (
    <ul className='flex flex-col gap-2 mt-2 mb-4 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6'>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 text-lg font-bold'
      >
        <Link
          to=''
          className='flex items-center transition-all duration-300 cursor-pointer font-link-muilsih hover:text-main-orange'
        >
          Pages
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 text-lg font-bold'
      >
        <Link
          to=''
          className='flex items-center transition-all duration-300 cursor-pointer font-link-muilsih hover:text-main-orange'
        >
          Services
        </Link>
      </Typography>
      <Typography
        as='li'
        variant='small'
        color='blue-gray'
        className='p-1 text-lg font-bold'
      >
        <Link
          to=''
          className='flex items-center transition-all duration-300 cursor-pointer font-link-muilsih hover:text-main-orange'
        >
          Company
        </Link>
      </Typography>
    </ul>
  )

  return (
    <>
      <div className='sticky top-0 z-50 w-full bg-main-blue'>
        <Navbar
          className='max-w-screen-xl px-4 mx-auto border-none max-lg:px-8 lg:py-4 '
          id='Navbar'
        >
          <div className='container flex items-center justify-between mx-auto text-blue-gray-900'>
            <Link to='' className='cursor-pointer'>
              <img src={Logo} className='w-60' alt='' />
            </Link>
            <div className='hidden lg:block'>{navList}</div>
            <Button
              variant='filled'
              size='sm'
              className='hidden lg:block !bg-transparent !shadow-none'
            >
              <div className='flex space-x-8'>
                <div>
                  <ButtonLink href='' text='Talk to an Expert' />
                  {/* <Link to=''>
                    <div className='px-6 py-5 text-lg font-bold leading-6 transition-all duration-300 border cursor-pointer - 2 border-main-orange bg-main-orange hover:bg-orange-hover'>
                      Talk to an Expert
                    </div>
                  </Link> */}
                </div>
                <div>
                  <Link to='' className=''>
                    <div className='box-border px-6 py-5 text-lg font-bold leading-6 transition-all duration-300 border-[1px] cursor-pointer border-main-orange hover:bg-orange-hover'>
                      Login
                    </div>
                  </Link>
                </div>
              </div>
            </Button>
            <IconButton
              variant='text'
              className='w-6 h-6 ml-auto text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden'
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  className='w-6 h-6'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='w-6 h-6'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M4 6h16M4 12h16M4 18h16'
                  />
                </svg>
              )}
            </IconButton>
          </div>
          <Collapse open={openNav}>
            <div className='container mx-auto'>
              {navList}
              <Button
                variant='filled'
                size='lg'
                className='!bg-transparent !shadow-none'
              >
                <div className='flex space-x-8'>
                  <div>
                    <ButtonLink href='' text='Talk to an Expert' />
                  </div>
                  <div>
                    <Link to='' className=''>
                      <div className='box-border flex justify-center items-center h-full px-3 py-2 text-lg font-bold leading-6 transition-all duration-300 border-[1px] cursor-pointer border-main-orange hover:bg-orange-hover'>
                        Login
                      </div>
                    </Link>
                  </div>
                </div>
              </Button>
            </div>
          </Collapse>
        </Navbar>
      </div>
    </>
  )
}
