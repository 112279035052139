import { Link } from 'react-scroll'

export default function ButtonLinkBorder (props) {
  return (
    <>
      <Link to={props.href}>
        <div className='px-6 py-5 text-lg font-bold leading-6 transition-all duration-300 border cursor-pointer text-main-orange w-fit border-main-orange hover:bg-orange-hover hover:scale-105 font-link-muilsih hover:text-white'>
          {props.text}
        </div>
      </Link>
    </>
  )
}
