import { Link } from 'react-scroll'
import logo from '../../assets/img/footer-logo.avif'
import { List, ListItem, Card, Typography } from '@material-tailwind/react'
import { BsEnvelope, BsPhone } from 'react-icons/bs'
import { GrLocation } from 'react-icons/gr'
export default function Footer () {
  const list = [
    {
      title: 'Services',
      lists: [
        '3D Scanning',
        'As-Built Drawings',
        '3D Models',
        'Architectural Plans',
        'Structural Engineering',
        'Construction Docs',
        'Project Permitting',
        'View All'
      ]
    },
    {
      title: 'Tools',
      lists: [
        'Project Board',
        'Digital Measure',
        'Customer Portal',
        'Instant Quotes',
        'To-Do Lists',
        'File Sharing',
        'Team Chat',
        'View All'
      ]
    },
    {
      title: 'Company',
      lists: [
        'About',
        'Careers',
        'Platform Overview',
        'How It Works',
        'Contact Us',
        'Schedule Call'
      ]
    }
  ]

  return (
    <>
      <div className='w-full bg-[#ecf2f9] py-24'>
        <div className='grid max-w-screen-xl mx-auto sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-0 max-xl:px-6'>
          <div className='w-full mb-4'>
            <div>
              <img src={logo} alt='' />
            </div>
            <div className='mt-6 text-xl font-normal font-link-muilsih text-[#262729]'>
              All-in-one preconstruction platform for home builders and GCs.
            </div>
          </div>
          {list &&
            list.map((item, index) => {
              return (
                <Card className='w-full bg-transparent border-none shadow-none'>
                  <List>
                    <Typography className='px-3 text-2xl font-bold text-black max-md:p-0 font-link-muilsih'>
                      {item.title}
                    </Typography>
                    {item.lists &&
                      item.lists.map((itm, ind) => {
                        return (
                          <ListItem className='m-0 py-2 max-md:p-0 text-lg text-[#262729] transition-all duration-300 hover:text-main-orange font-normal font-link-muilsih'>
                            <Link to='' className=''>
                              {itm}
                            </Link>
                          </ListItem>
                        )
                      })}
                  </List>
                </Card>
              )
            })}
          <Card className='w-full bg-transparent border-none shadow-none'>
            <List className='p-0'>
              <ListItem className='flex py-2 m-0 space-x-2'>
                <span>
                  <BsEnvelope className='text-xl' />
                </span>
                <span className='xl:text-xl text-[#262729] transition-all duration-300 hover:text-main-orange font-normal font-link-muilsih'>
                  info@constructo.online
                </span>
              </ListItem>
              <ListItem className='flex py-2 m-0 space-x-2'>
                <span>
                  <BsPhone className='text-xl' />
                </span>
                <span className='xl:text-xl text-[#262729] transition-all duration-300 hover:text-main-orange font-normal font-link-muilsih'>
                  (800) 970-1766
                </span>
              </ListItem>
              <ListItem className='flex items-start py-2 m-0 space-x-2'>
                <span>
                  <GrLocation className='text-xl' />
                </span>
                <span className='xl:text-lg text-[#262729] transition-all duration-300 hover:text-main-orange font-normal font-link-muilsih'>
                  900 Campisi Way, Ste. 2C Campbell, CA 95008
                </span>
              </ListItem>
            </List>
          </Card>
        </div>
      </div>
    </>
  )
}
