import { Link } from "react-scroll";

export default function ButtonLink(props) {
    return (
        <>
            <Link to={props.href}>
                <div className='px-6 w-fit py-5 text-lg font-bold leading-6 transition-all duration-300 border cursor-pointer  border-main-orange bg-main-orange hover:bg-orange-hover hover:scale-105 text-white  font-link-muilsih'>
                    {props.text}
                </div>
            </Link>
        </>
    )
}