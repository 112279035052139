import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button
} from '@material-tailwind/react'
import ButtonLink from '../repeatable/buttonLink'
import imageMarker from '../../assets/img/marker.svg'
import ButtonLinkBorder from '../repeatable/buttonLinkBorder'
export default function CardSolution (props) {
  return (
    <>
      <Card
        className={`${props.class} w-full bg-transparent shadow-none border-none`}
      >
        <CardHeader
          shadow={false}
          floated={false}
          className='m-0 bg-transparent rounded-r-none lg:w-1/2 shrink-0'
        >
          <img src={props.image} className='object-cover w-full h-full' />
        </CardHeader>
        <CardBody className='lg:w-1/2 max-sm:px-0'>
          <Typography
            variant=''
            color=''
            className='mb-2 text-xl font-bold text-black sm:text-4xl font-link-muilsih'
          >
            {props.title}
          </Typography>
          <Typography
            variant='p'
            color='blue-gray'
            className='mt-2 sm:mt-6 mb-6 sm:text-[24px] font-normal text-black tracking-wide'
          >
            {props.description}
          </Typography>
          {props.data &&
            props.data.map((item, index) => {
              return (
                <Typography
                  color='gray'
                  className='flex mt-2 space-x-4 font-normal text-black sm:text-xl'
                  key={index}
                >
                  <div>
                    <img src={imageMarker} alt='' />
                  </div>
                  <div>{item.desc}</div>
                </Typography>
              )
            })}
          <div className='mt-8'>
            <ButtonLinkBorder text='Learn More' />
          </div>
        </CardBody>
      </Card>
    </>
  )
}
