import { Typography } from '@material-tailwind/react'

export default function TitleBlack (props) {
  return (
    <>
      <Typography className=' text-[20px] sm:text-[46px] font-extrabold text-center font-link-muilsih tracking-[0.1em]'>
        {props.start}
        <span className='px-4 after:bg-cover relative  after:absolute after:-bottom-2 after:left-0 after:w-full after:h-[8px] after:bg-shape-bg after:bg-no-repeat  overflow-visible'>
          {props.after}
        </span>
        {props.end}
      </Typography>
      <Typography className='max-w-screen-lg px-6 mx-auto mt-6 text-sm font-medium text-center text-black sm:text-xl'>
        {props.desc}
      </Typography>
    </>
  )
}
