import BG from '../../assets/img/hero-bg.svg'
import TitleBlack from '../repeatable/titleBlack'
import PlatformTab from './platformTab'
export default function Platform () {
  return (
    <>
      <div className='relative pt-24 pb-64 bg-black bg-opacity-20 bg-hero-bg after:w-full after:h-full after:top-0 after:left-0 after:absolute after:bg-[#f8f9fc]/75 z-10'>
        <div className='relative z-50 max-w-screen-xl mx-auto'>
          <TitleBlack
            start='One'
            after='platform'
            end='for total project control.'
            desc='We combine best-in-class preconstruction services and built-in workflow tools for total project control. Request services, stay organized, and collaborate with your customers in one place.'
          />
          <PlatformTab />
        </div>
      </div>
    </>
  )
}
