import TitleWhite from '../repeatable/titleWhite'
import image1 from '../../assets/img/feature-illustration-1.svg'
import image2 from '../../assets/img/feature-illustration-2.svg'
import image3 from '../../assets/img/feature-illustration-3-svg.svg'
import { Typography } from '@material-tailwind/react'
import ButtonLink from '../repeatable/buttonLink'
export default function Steps () {
  const list = [
    {
      image: image1,
      title: 'Step 1: Measure',
      desc: 'Get accurate 3D site measurements and as-built drawings that guide the design process of your residential construction project.'
    },
    {
      image: image2,
      title: 'Step 2: Design',
      desc: 'Design custom home concepts and prepare full project plan sets with all the necessary paperwork for pulling building permits.'
    },
    {
      image: image3,
      title: 'Step 3: Permit',
      desc: 'Put permitting on autopilot with a dedicated Constructo agent that works directly with the city and building department officials. '
    }
  ]

  return (
    <>
      <div
        className='w-full bg-[#002a58] py-16 max-xl:px-2 overflow-hidden'
        id='Steps'
      >
        <TitleWhite
          start='We streamline preconstruction,'
          after='start to finish.'
          desc="Constructo integrates and guides every step of your project's preconstruction process."
        />
        <div className='flex items-center justify-between max-w-screen-xl mx-auto mt-12 space-x-6 max-md:space-y-8 max-md:flex-col'>
          {list &&
            list.map((item, index) => {
              return (
                <div
                  className={
                    index % 2 == 0
                      ? 'w-full md:w-1/3 px-8 md:mt-24'
                      : 'w-full md:w-1/3 px-8 mt-0'
                  }
                  key={index}
                >
                  <div className=''>
                    <img src={item.image} className='w-full h-full' alt='' />
                  </div>
                  <div className='mt-4'>
                    <Typography className='text-3xl font-bold text-center text-white'>
                      {item.title}
                    </Typography>
                    <Typography className='mt-2 text-lg font-normal text-center text-white'>
                      {item.desc}
                    </Typography>
                  </div>
                </div>
              )
            })}
        </div>
        <div className='flex justify-center py-6 max-xl:px-6'>
          <ButtonLink text='See How It Works' />
        </div>
      </div>
    </>
  )
}
