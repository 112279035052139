import TitleBlack from '../repeatable/titleBlack'
import TestimonialsSlider from './testimonialsSlider'

export default function Testimonials () {
  return (
    <>
      <div className='bg-[#f8f9fc] py-12 px-6'>
        <TitleBlack
          start='Join'
          after='hundreds'
          end='of builders using Constructo.'
        />
        <TestimonialsSlider />
      </div>
    </>
  )
}
