import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button
} from '@material-tailwind/react'
import HeroImg from '../../assets/img/hero-image.avif'
import ShapeImg from '../../assets/img/hero-title-shape.svg'
import ButtonLink from '../repeatable/buttonLink'
export default function Hero () {
  return (
    <>
      <div className='md:h-[100vh] bg-main-blue bg-hero-bg bg-cover bg-repeat flex items-center'>
        <Card className='flex-row max-md:flex-col max-w-screen-xl mx-auto !bg-transparent shadow-none'>
          <CardBody>
            <Typography
              variant='h6'
              color=''
              className='text-white rounded-2xl  py-2 px-6 w-fit text-lg font-bold mb-4 capitalize bg-[#0b3666]'
            >
              All-in-One Preconstruction Platform
            </Typography>
            <Typography
              variant='h4'
              color='blue-gray'
              className='mb-2 text-2xl sm:text-[60px] text-white font-black leading-10 sm:leading-[72px] tracking-[0.05em]'
            >
              Go from design to permit,{' '}
              <span
                className={`relative  after:absolute after:-bottom-2 after:left-0 after:w-full after:h-[12px] after:bg-shape-bg after:bg-no-repeat  overflow-visible`}
              >
                fast
              </span>
              .
            </Typography>
            <Typography
              color='white'
              className='mt-4 font-semibold text-white sm:text-2xl sm:mt-12 '
            >
              Constructo streamlines the entire preconstruction process for all
              your residential projects–from 3D scans and as-builts to
              permit-ready plan sets.
            </Typography>
            <div className='mt-12'>
              <ButtonLink href='' text='Talk to an Expert' />
            </div>
          </CardBody>
          <CardHeader
            shadow={false}
            floated={false}
            className='m-0 bg-transparent rounded-r-none md:w-1/2 shrink-0 max-xl:px-6'
          >
            <img src={HeroImg} className='object-cover w-full h-full' />
          </CardHeader>
        </Card>
      </div>
    </>
  )
}
