import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar
} from '@material-tailwind/react'

function StarIcon () {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='currentColor'
      className='w-5 h-5 text-yellow-700'
    >
      <path
        fillRule='evenodd'
        d='M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z'
        clipRule='evenodd'
      />
    </svg>
  )
}

const list = [
  {
    text: 'Working with Constructo has been a huge plus. Having a reliable and professional team we can reach out to for drafting, engineering, and permit acquisition takes the strain off our limited team.',
    name: 'Juan Regalado',
    type: 'President, J&A Builders'
  },
  {
    text: '“We’re working on 3 projects with Constructo, and our clients love the 3D modeling - and we love that it’s included in the full price! We can budget easily with their flat-rate pricing.”',
    name: 'Erez Zilberberg',
    type: 'Owner, California Construction Cente'
  },
  {
    text: '"Having predictability in the preconstruction process is a must, and Constructo checks that box and more. We get upfront pricing plus a team that we can rely on throughout the entire process.”',
    name: 'Michael Abrishamcar',
    type: 'Owner, Peninsula Design & Construction'
  },
  {
    text: '“Constructo is our dedicated design and permitting team. They’ve taken the hassle out of preconstruction, and since using them, we’re breezing through the process and winning more jobs.”',
    name: 'Joey Kochanski',
    type: 'Owner, Woodcliff Builders'
  },
  {
    text: '“Constructo took over our complicated 2-story addition project, which was abandoned by our architect, and did a great job communicating with our client and delivering the full set of plans.”',
    name: 'Juan Regalado',
    type: 'President, J&A Builders'
  },
  {
    text: '“Preconstruction was always a missed opportunity for us. With Constructo, we found an actual partner that guides us. Now we actually make a profit in the design phase of our projects.”',
    name: 'CJ Stickels',
    type: 'President, Stickels Pro Builders'
  }
]

export default function TestimonialsSlider () {
  return (
    <>
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        onSwiper={swiper => console.log(swiper)}
        breakpoints={{
          768: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          960: {
            slidesPerView: 3,
            spaceBetween: 20
          }
        }}
      >
        {list &&
          list.map((item, index) => {
            return (
              <SwiperSlide>
                <Card
                  color='transparent'
                  shadow={false}
                  className='w-full px-8 py-8 bg-white'
                >
                  <CardHeader
                    color='transparent'
                    floated={false}
                    shadow={false}
                    className='flex items-center gap-4 pt-0 pb-8 mx-0'
                  >
                    <div className='flex w-full flex-col gap-0.5'>
                      <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-0 5'>
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className='p-0 mb-6'>
                    <Typography className='h-64 text-lg sm:h-32'>
                      {item.text}
                    </Typography>
                    <Typography
                      variant='h4'
                      color='blue-gray'
                      className='mt-6 mb-2 font-bold font-link-muilsih'
                    >
                      {item.name}
                    </Typography>
                    <Typography color='gray' className='font-normal'>
                      {item.type}
                    </Typography>
                  </CardBody>
                </Card>
              </SwiperSlide>
            )
          })}
      </Swiper>
    </>
  )
}
