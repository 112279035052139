import { Typography } from '@material-tailwind/react'

export default function TitleWhite (props) {
  return (
    <>
      <div className='max-w-screen-xl mx-auto text-white'>
        <Typography className=' text-[24px] sm:text-[46px] font-extrabold text-center font-link-muilsih tracking-[0.1em]'>
          {props.start}
          <span className='px-4 after:bg-cover relative  after:absolute after:-bottom-2 after:left-0 after:w-full after:h-[8px] after:bg-shape-bg after:bg-no-repeat  overflow-visible'>
            {props.after}
          </span>
        </Typography>
        <Typography className='mt-6 font-medium text-center text-white sm:px-24 sm:text-2xl'>
          {props.desc}
        </Typography>
      </div>
    </>
  )
}
