import React from 'react'
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel
} from '@material-tailwind/react'
import img1 from '../../assets/img/tabs/icon (1).svg'
import img2 from '../../assets/img/tabs/icon (2).svg'
import img3 from '../../assets/img/tabs/icon (3).svg'
import img4 from '../../assets/img/tabs/icon (4).svg'
import img5 from '../../assets/img/tabs/icon (5).svg'
import img6 from '../../assets/img/tabs/icon (6).svg'
import img7 from '../../assets/img/tabs/icon (7).svg'
import img8 from '../../assets/img/tabs/icon (8).svg'
import img9 from '../../assets/img/tabs/icon (9).svg'
import img10 from '../../assets/img/tabs/icon (10).svg'
import img11 from '../../assets/img/tabs/Group 1042.svg'
import img12 from '../../assets/img/tabs/icon (11).svg'
import img13 from '../../assets/img/tabs/icon (12).svg'
import img14 from '../../assets/img/tabs/icon (13).svg'
import img15 from '../../assets/img/tabs/icon (14).svg'
import img16 from '../../assets/img/tabs/icon (15).svg'

import { Link } from 'react-scroll'
import { BiChevronRight } from 'react-icons/bi'
export default function PlatformTab () {
  const [activeTab, setActiveTab] = React.useState('service')
  const data = [
    {
      label: 'services',
      value: 'service',
      desc: [
        {
          image: img1,
          title: '3D Scanning'
        },
        {
          image: img2,
          title: 'Architectural Plans'
        },
        {
          image: img3,
          title: 'As-built Drawings'
        },
        {
          image: img4,
          title: 'Construction Documents'
        },
        {
          image: img5,
          title: 'Conceptual Designs'
        },
        {
          image: img6,
          title: 'Permitting Agent'
        },
        {
          image: img7,
          title: '3D Modeling'
        },
        {
          image: img8,
          title: 'Structural Engineering'
        }
      ]
    },
    {
      label: 'Tools',
      value: 'tools',
      desc: [
        {
          image: img9,
          title: 'Project Board'
        },
        {
          image: img10,
          title: 'Team Chat'
        },
        {
          image: img11,
          title: 'File Sharing'
        },
        {
          image: img12,
          title: 'To-do Lists'
        },
        {
          image: img13,
          title: 'Digital Measuring'
        },
        {
          image: img14,
          title: 'Customer Portal'
        },
        {
          image: img15,
          title: 'Task Management'
        },
        {
          image: img16,
          title: 'Instant Quotes'
        }
      ]
    }
  ]

  return (
    <>
      <div className='max-w-screen-lg mx-auto mt-16 max-xl:px-6'>
        <Tabs value={activeTab}>
          <TabsHeader
            className='p-0 bg-transparent border-b rounded-none border-blue-gray-50'
            indicatorProps={{
              className:
                'bg-transparent border-b-2 border-main-orange shadow-none rounded-none'
            }}
          >
            {data.map(({ label, value }) => (
              <Tab
                key={value}
                value={value}
                onClick={() => setActiveTab(value)}
                className={
                  activeTab === value
                    ? 'text-main-orange sm:text-2xl font-bold uppercase font-link-muilsih py-6'
                    : 'text-black sm:text-2xl font-bold uppercase font-link-muilsih py-6'
                }
              >
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody>
            {data.map(({ value, desc }) => (
              <TabPanel
                key={value}
                value={value}
                className='grid grid-cols-1 p-0 py-12 md:grid-cols-2 gap-x-8 gap-y-12'
              >
                {/* {desc} */}
                {desc &&
                  desc.map((item, index) => {
                    return (
                      <div className='flex items-center w-full px-8 py-12 space-x-4 bg-white font-link-muilsih'>
                        <div className='w-1/5'>
                          <img src={item.image} alt='' />
                        </div>
                        <div className='w-4/5 text-xl font-bold text-black sm:text-3xl'>
                          <div>{item.title}</div>
                          <div>
                            <Link
                              to=''
                              className='flex items-center space-x-2 cursor-pointer'
                            >
                              <span className='text-lg text-main-orange'>
                                Explore More
                              </span>
                              <BiChevronRight className='text-2xl text-main-orange' />
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </div>
    </>
  )
}
