import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Avatar,
  Tooltip
} from '@material-tailwind/react'

export default function CardChoose (props) {
  return (
    <>
      <Card className='overflow-hidden bg-transparent shadow-none lg:w-1/3'>
        <CardHeader
          floated={false}
          shadow={false}
          color=''
          className='flex items-center justify-center h-64 m-0 bg-transparent rounded-none'
        >
          <img
            src={props.image}
            alt='Choose Image'
            className='w-full h-full '
          />
        </CardHeader>
        <CardBody className='mt-8 max-lg:p-0'>
          <Typography
            variant='h4'
            color=''
            className='p-0 font-bold text-center text-white sm:text-3xl font-link-muilsih'
          >
            {props.title}
          </Typography>
          <Typography
            variant='lead'
            color='gray'
            className='mt-8 font-normal text-center text-white sm:text-2xl'
          >
            {props.desc}
          </Typography>
        </CardBody>
      </Card>
    </>
  )
}
