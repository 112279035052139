import BeforeFooter from './components/beforeFooter'
import Choose from './components/choose'
import Hero from './components/hero'
import Platform from './components/platform'
import Solution from './components/solution'
import Steps from './components/steps'
import Testimonials from './components/testimonials'

export default function Home () {
  return (
    <>
      <Hero />
      <Testimonials />
      <Steps />
      <Solution />
      <Choose />
      <Platform />
      <BeforeFooter />
    </>
  )
}
